<template>
  <div class="todo-app-list bg-white">
    <ekle-headers
      title="'Ürün Fotografları'"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-row>
        <b-col cols="12">
          <b-alert variant="warning" class="text-center rounded-0" show>
            <h4 class="alert-heading">Fotoğraf Ekleyin.</h4>
            <div class="alert-body">
              Eğlenen gezginleri gösteren ve ürününüzün benzersiz yönlerini vurgulayan fotoğrafları kullanın. En yüksek kalite
              standartları mızla uyumlu hâle gelebilmek ve rezervasyon potansiyelinizi artırabilmek için en az 6 fotoğraf ekleyin.
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-complete="handlerComplated"
            @vdropzone-sending="sendingEvent"
            v-on:vdropzone-removed-file="handlerRemoveItem"
          />
        </b-col>
        <b-col cols="12">
          <b-button squared variant="primary" size="lg" class="float-right mt-2" @click="onSubmit" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="22" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Kaydet ve Devam et
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card-normal
            :title="`Görseller ( Toplam : ${images.length} Adet )`"
            :sub-title="`Sürükleyerek resim sırasını değiştirebilir ve kapak resmini belirkeyebilirsiniz.`"
            icon="ImageIcon"
            class="mt-1 rounded-0"
          >
            <template v-slot:body>
              <draggable v-model="images" handle=".draggable-task-handle" @end="handlerResimSiraChange">
                <transition-group type="transition" name="flip-list" class="row" tag="div">
                  <b-col v-for="item in images" :key="item.k_no" cols="12" sm="12" md="3" lg="3">
                    <div class="grid">
                      <b-img
                        thumbnail
                        fluid
                        rounded
                        class="draggable-task-handle"
                        :class="item.kapak ? 'border-success' : ''"
                        :src="imageFile(item.gorsel)"
                        :alt="getUrun.icerik['tr'].urun_adi"
                        v-b-tooltip.hover.top="'Sıra değiştirme için sürükleyebilirsiniz.'"
                      />
                      <feather-icon
                        v-if="item.kapak"
                        class="text-success cursor-pointer secilen-kapak"
                        size="24"
                        icon="MonitorIcon"
                        v-b-tooltip.hover.top="'Kapak Resmi'"
                      />
                      <div class="figcaption">
                        <feather-icon
                          class="text-danger cursor-pointer mr-1"
                          size="24"
                          icon="Trash2Icon"
                          @click="handlerRemove(item)"
                          v-b-tooltip.hover.top="'Görsel Sil!'"
                        />
                        <feather-icon
                          v-if="!item.kapak"
                          class="text-primary cursor-pointer"
                          size="24"
                          icon="MonitorIcon"
                          @click="urunResimKapak(item)"
                          v-b-tooltip.hover.top="'Kapak Resmi Yap.'"
                        />
                      </div>
                    </div>
                  </b-col>
                </transition-group>
              </draggable>
            </template>
          </b-card-normal>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import EkleHeaders from '../component/ekleHeader.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    vueDropzone: vue2Dropzone,
    EkleHeaders,
    BCardNormal,
  },
  props: {
    form: {
      type: Object,
      required: false,
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      k_no: null,
      images: [],
      dropzoneOptions: {
        paramName: 'urunler',
        url:
          process.env.NODE_ENV == 'development'
            ? process.env.VUE_APP_DEV_BASEURL + '/urun/urun-resim-ekle'
            : process.env.VUE_APP_PRO_BASEURL + '/urun/urun-resim-ekle',
        method: 'POST',
        thumbnailWidth: 320,
        maxFilesize: 10,
        parallelUploads: 10,
        maxFile: 10,
        addRemoveLinks: true,
        dictDefaultMessage: 'Dosyaları yüklemek için buraya bırakın',
        uploadMultiple: false,
        autoProcessQueue: false,
        headers: { Authorization: 'Bearer: ' + localStorage.getItem('accessToken') },
      },
    };
  },

  computed: {
    ...mapGetters(['getUrun']),
    imageFile() {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

      return (img) => `${base_url}uploads/urunler/thumbnail/${img}`;
    },
  },

  methods: {
    async handlerComplated(response) {
      const res = JSON.parse(response.xhr.response);
      if (res.success === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Bilgi`,
            icon: 'CheckSquareIcon',
            variant: 'success',
            text: 'Ekleme Başarılı.',
          },
        });
        this.$store.commit('SET_URUN', res.data);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Hata`,
            icon: 'CheckSquareIcon',
            variant: 'success',
            text: 'Hata Oluştu.',
          },
        });
      }

      this.show = false;
      this.$refs.myVueDropzone.removeAllFiles();
      this.$emit('closeModal', false);
    },
    sendingEvent(files, xhr, formData) {
      const firma_k_no = this.$store.getters.getUserData.firma_k_no;
      formData.append('k_no', this.k_no);
      formData.append('firma_k_no', firma_k_no);
    },

    onSubmit() {
      this.show = true;
      this.$refs.myVueDropzone.processQueue();
    },
    handlerRemoveItem(file, error, xhr) {
      // console.log(file);
    },
    handlerRemove(item) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('urunResimSil', item).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
    urunResimKapak(item) {
      this.$store.dispatch('urunResimKapak', item).then((res) => {
        if (res.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'Trash2Icon',
              variant: 'success',
              text: `Kapak resmi olarak ayarlandı.`,
            },
          });
        }
      });
    },
    handlerResimSiraChange() {
      this.$store
        .dispatch('urunResimSiraGuncelle', { k_no: this.k_no, gorseller: this.images })
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Sira Güncelleme Başarılı.',
              },
            });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
  watch: {
    getUrun: {
      handler(val) {
        this.images = val.gorseller;
        this.k_no = val.k_no;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.dropzone {
  border: 1px solid #ebe9f0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.secilen-kapak {
  position: absolute !important;
  top: 8px;
  right: 20px;
}
.grid {
  .figcaption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    width: 100%;
    left: 0;
    right: 0;
  }
  &:hover {
    position: relative;
    background-color: rgba($color: #000000, $alpha: 1);
    img {
      opacity: 0.4;
    }
    .figcaption {
      opacity: 9;
    }
  }
}
</style>
